.App {
  text-align: left;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.legend {
  margin: 0 15px 0 0;
  display: inline-block;
}

.legend span {
  width: 15px;
  height: 15px;
  display: inline-block;
  margin-right: 3px;
  position: relative;
  top: 3px;
  border-radius: 50%;
}

.legend .late-color {
  background-color: #E6867A;
}

.legend .half-day-color {
  background-color: #ECE988;
}

.legend .leave-color {
  background-color: #a4eed2;
}

.legend .holiday-color {
  background-color: #848484;
}

.legend .check-missing-color {
  background-color: #89BAE0;
}

.legend .global-late-color {
  background-color: #C6BBA6;
}

.legend .absent-color {
  background-color: #5D0F0D;
}

.legend .attach-holiday-color {
  background-color: #9694bd;
}
.MuiTableCell-root  {
  padding: 10px 0 10px 10px !important;
}
.full-screen-modal .modal-dialog {
  max-width: 100vw;
  min-height: 100vh;
  margin: 0;
}

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(128, 128, 128, 0.5); /* Grey background with 50% opacity */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999; /* Make sure it overlays other content */
}

.loader {
  text-align: center;
}

.loader-message {
  margin-top: 10px;
  font-size: 16px;
  color: #000; /* You can adjust the color as needed */
}

.shadow-card { 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
  border-radius: 8px; 
}