@import url('https://fonts.googleapis.com/css?family=Open+Sans:600|Work+Sans:400,500,600,700&display=swap');

body{
  font-family: "work sans";
  height: auto; 
}

.fullScreen{
  min-height: 87.5vh;
}

html{
  font-size: 100%;
}

#loginform{
  max-width: 500px;
  min-width: 300px;
  max-height: 700px;
  width: 30%;
  height: 100%;
  margin: 100px auto;
  background-color: #FFFFFF;
}

#headerTitle{
  text-align: center;
  font-family: 'open sans', sans-serif;
  padding: 2rem 0;
  margin: 0;
  font-size: 2rem;
}

.row{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2rem;
  max-width: 100%;
}

.row input{
  width: 80%;
  box-sizing: border-box;
	border: none;
  font-size: 1.3rem;
  padding-left: 0rem;
  padding-bottom: 1rem;
  box-shadow: inset 0px -3px 0px 0px rgba(68, 68, 68, 0.2);
  transition: box-shadow 0.2s ease-in;
}
input::-webkit-input-placeholder {
  font-size: 15px;
  line-height: 1;
  color: #afafaf;
}

.row input:focus{
   box-shadow: inset 0px -3px 0px 0px rgba(34,193,195,0.7);
   outline: none;
}

.row input::-webkit-input-placeholder{
  opacity: 1;
  transition: opacity 0.25s ease-out;
}

.row input:hover::-webkit-input-placeholder,
.row input:focus::-webkit-input-placeholder{
  opacity: 0;
}

.row label{
  align-self: start;
  padding-left: 2.5rem;
  padding-bottom: 0.5rem;
  color: rgba(59, 59, 59, 0.9);
}

.row button{
  width: 80%;
  height: 40px;
  font-size: large;
  color: white;
  background: #077c21;
  cursor: pointer;
  transition: opacity 0.25s ease-out;
  border: none;
  font-weight: 600;
}

.row button:hover{
  opacity: 0.8;
}

#button{
  padding-bottom: 1.5rem;
}

.setupButtons{
  margin: 15px 0px 15px 0px;
  text-align: right;
}

.row a {
   width: 80%;
   text-align: right;
   padding-bottom: 2rem;
   font-size: 0.8rem;
}
.footer{
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.685);
  bottom: 0;
  width: 100%;
}

.DefaultProfilePic{
  width:"10";
  height:"50";
}
.Profilepic{
  display: flex;
  padding-top: 10px;
}
.Sameline{
  display: flex;
}
.mytext{
  text-align: center;
}
.Samelinetext{
  display: flex;
  width: 150px;
  padding: 0px 0px 0px 0px;
}
.Samelinetext1{
  display: flex;
  clear: both;
  width: max-content;
}
.Samelinetextnav{
  display: inline-block;
  width: 100%;
  text-decoration: none;
  padding: 0px 10px 6px 10px;
  color: white;
  white-space: nowrap;
}
.Samelinetextnav:hover{
color: white;
background-color: #595959;
}
.my-custom-view-wrapper {
  display: flex;
  padding-left: 50px;
}
.my-custom-view-wrapper div:last-child {
   /* Here we are styling the button container.  */
  display: flex;
  padding: 0px 0px 0px 0px;
  justify-content: center;
  align-items: center;
}

.loader
{
  min-height: 100%;
  display: flex;
            align-items: center;
            position: absolute;
            top: 50%;
            left: 50%;
            margin-right: -50%;
            transform: translate(-50%, -50%);
}
.footerButtonsDiv
{
  float: right;
}
.full-screen-modal .modal-dialog {
  max-width: 100vw;
  min-height: 100vh;
  margin: 0;
}